.search {
    &-container {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: $bluegray;
    // overflow-y: hidden;
    transition: 0.5s;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 99999;
    }

    &-inner-container {
        min-height: calc(100vh - 368px);
        padding-bottom: 50px;
    }

    &-close {
        background-color: $white; 
        cursor: pointer;
        padding: 30px 0;

        @include below ($s) {
            padding: 20px 0;
        }

    }

    &-inner {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        img {
            margin-right: 10px;
        }

        p {
            font-size: 14px; 
            color: #F04A77;
            text-transform: uppercase;
        }
    }

    &-holder {
        padding-top: 100px;
        @include below ($s) {
            padding-top: 50px;
        }
    }

    &-label {
        position: relative;
        width: 512px;
        display: block;
        margin: 0 auto; 
        border-bottom: 2px solid #454F63;
        padding-bottom: 10px; 
        @include below ($m) {
            width: 90%; 
        }
       }

    &-submit {
        width: 24px;
        height: 24px;
        background-image: url(../img/icon-search.svg);
        background-repeat: no-repeat;
        position: absolute;
        left: 5px;
        appearance: none;
        border: none;
        background-color: transparent;
        outline: 0;
    }

    &-input {
        border: none;
        background-color: transparent;
        padding-left: 50px;
        font-size: 17px;
        outline: none;
        width: calc(100% - 50px);

        &::placeholder {
            color:#454F63;
            opacity: 1; /* Firefox */
            font-family: $open;
        }
          
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #454F63;
        font-family: $open;
        }
          
        &::-ms-input-placeholder { /* Microsoft Edge */
        color: #454F63;
        font-family: $open;
        }
    }

        &-results {
            width: 512px;
            margin: 0 auto;

            @include below ($m) {
                width: 90%; 
            }

            &-text {
                text-align: center;
                color: #454F63; 
                font-size: 16px;
                margin: 50px 0;

                @include below ($s) {
                    margin: 30px 0;
                } 
            }

            &-item {
                display: block;
                background-color: white;
                padding: 15px;
                min-height: 119px;
                position: relative;
                margin-bottom: 25px;

                img {
                    position: absolute; 
                    bottom: 15px;
                    right: 15px;
                }

            }
        }
}

// .footer-search {
//     position: absolute;
//     width: 100%;
//     bottom: 0;
// }