footer {
    background-color: $blue;
    text-align: center; 
    padding: 50px 20px;
    font-size: 15px; 
}

.footer-links-holder {
    margin: 45px 0;
    a {
        display: block;
        color: $white;
        font-weight: 700; 

        &:first-of-type {
            margin-bottom: 15px; 
        }
    }
}

.copyright-text {
    font-size: 12px;
}