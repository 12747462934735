.nav {
    &-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translatex(-50%);
    display: none;

    @include below ($s) {
        width: 100%;
    }
    }

    &-inner-container {
        width: 500px; 
        background-color: $darkestblue;
        padding: 0px 40px 20px; 

        a {
            color: rgba(255, 255, 255, 0.6); 
            text-align: center;
            font-family: $open;
            font-size: 16px;
            padding-top: 10px;
            display: inline-block;
            width: 49%; 
        }

        @include below ($s) {
            width: 100%; 
            padding: 0 20px 20px;
        }

    }

    &-inner-top {
        width: 500px; 
        background-color: $darkestblue;
        height: 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        @include below ($s) {
            width: 100%; 
        }
    }

    &-inner {
        display: flex;
        justify-content: space-between;
        .cat-container {
            width: 110px;
            height: 86px;
            background-color: $darkblue;
            border-radius: 5px;
            position: relative; 
            cursor: pointer;

            @include below ($s) {
                width: 100px;
            }
            .cat-inner {
                position: absolute; 
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                h2 {
                    font-family: $open; 
                    font-size: 13px;
                    color: rgba(255, 255, 255, 0.6);
                }
            }

            &.active {
                background-color: $yellow;
                color: $white;

                .cat-inner {
                    h2 {
                        color: $white;
                    }
                }
            }
        }
    }
}

.tab {
    height: 25px;
    width: 50px;
    margin: 0 auto;
    position: relative; 
    background-color: $darkestblue;
    padding: 10px 10px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;

    span {
        background-color: rgba(255, 255, 255, 0.6); 
        position: absolute; 
        height: 1px; 
        width: 60%;
        top:12px;
        &:last-of-type {
            bottom: 5px;
            top: auto;
        }

    }
}   