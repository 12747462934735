.learn-more {
    &-img-holder {
        max-height: 480px;
        position: relative;

        @include below ($s) {
            margin-bottom: -3px;
        }

        &:before {
            position: absolute; 
            content: ''; 
            height: 100%; 
            width: 100%; 
            background-color: $darkestblue;
            opacity: 0;
            transition: all .2s ease-in-out;
        }

        &.overlay-visible {
            transition: all .1s ease-in-out;
            &:before {
                opacity: 0.74;
            }
        }

        img {
            @include below ($s) {
                width: 100%; 
            }
        }
    }

    &-text {
        position: absolute;
        top: 50%;
        width: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        opacity: 0;
        transition: all 0.2s ease-in-out;

        @include below ($s) {
            font-size: 15px; 
            line-height: 24px; 
        }


        &.visible {
            opacity: 1;
        }
    }
}

.learn-plus {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer; 
    background-image: url(../img/plus-sym.svg);
    height: 110px;
    width: 110px;
    background-size: cover;

    @include below ($s) {
        height: 90px; 
        width: 90px;
    }

    &.toggled {
        background-image: url(../img/minus-sym.svg);
    }
}

.learn-more-back-btn {
    position: absolute;
    cursor: pointer;
    top: 60px;
    left: 50px;
    transition: all .2s ease-in-out;

    &:hover {
        left: 45px;
    }

    @include below ($s) {
        display: none;
    }

    &-mobile {

        @include above ($s) {
            display: none;
        }
        position: absolute; 
        top: 20px;
        left: 20px;
        width: 50px;
        height: 50px;
        z-index: 999;

        img {
            width: 20px;
        }
    }
}