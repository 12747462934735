.content-title {
    color: #AFB2B4; 
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 20px;

    @include  below ($s) {
        margin-bottom: 10px;
    }
}

.content-link {
    font-weight: 700; 
    color: $blue;
    text-decoration: none;
    font-size: 16px;
    position: relative;
    display: inline-block;
    margin-bottom: 35px;

    &:after {
        background-image: url(../img/blue-arrow-right.svg);
        content: '';
        position: absolute;
        width: 19px;
        height: 16px;
        background-size: cover;
        top: 50%;
        transform: translateY(-50%);
        right: -30px;
        transition: all .2s ease-in-out; 
    }

    &:hover {
        &:after {
            right: -40px;
        }
    }
}

.content-info-holder {
    border: 1px solid $lightgrey; 
    padding: 25px;

    .title-s {
        margin-bottom: 20px;

        @include below ($s) {
            margin-bottom: 10px;
        }
    }
}

.content-season-holder {

    margin-bottom: 20px;
    padding-bottom: 20px; 
    border-bottom: 1px solid $lightgrey;

    .title-s {
        @include below ($s) {
            margin-bottom: 0;
        }
    }
    p {
        display: inline;

        @include below ($s) {
            display: block;
        }
    }
}

.content-map-holder {
    padding-bottom: 20px; 
    border-bottom: 1px solid $lightgrey;

    img {
        width: 100%;
    }
}

.content-find-holder {
    padding: 20px 0; 
    border-bottom: 1px solid $lightgrey;
}

.content-link-blue {
    font-weight: 700; 
    color: #3ACCE1;
}

.learn-more-back-btn-content {
    position: absolute;
    cursor: pointer;
    top: 50px;
    left: 50px;
    transition: all .2s ease-in-out;

    &:hover {
        left: 45px;
    }

    @include below ($s) {
        display: none;
    }
}