.slide-holder {
    max-height: 480px;

    @include below ($s) {
        margin-bottom: -3px;

    }
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background: #454F63 !important;
}

.swiper-pagination-bullet {
    background: $white;
    opacity: 1;
}

.swiper-slide {
    img {
        @include below ($s) {
            width:100%; 
        }
    }
}

.slider-caption-holder {
    position: absolute;
    bottom: 5px;
    padding: 10px 30px;
    background-color: rgba(0, 0, 0, 0.40);
    max-width: 400px;

    p {
        color: $white; 
        font-weight: 600;
        font-size: 14px;
    }
}

.slide-slider {
    position: relative;

    img {
        width: 100%;
    }
}