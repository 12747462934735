.info {
        &-container {
            // height: 0%;
            width: 100%;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            background-color: $bluegray;
            overflow-y: auto;
            transition: 0.5s;
            opacity: 0; 
            height: 100%;
            visibility: hidden;
            -webkit-overflow-scrolling: touch;
    }

    &-logo {
        position: absolute; 
        width: 500px; 
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%);

        @include below ($s) {
            width: 200px;
        }
    }

    &-hero {
        height: 480px; 
        background-color: $blue;
        position: relative;

        @include below ($s) {
            height: 230px;
        }

        img {
            @include below ($s) {
                width: 200px;
            }
        }
    }

    &-content {
        padding: 60px 0 40px;
        position: relative;

        @include below ($s) {
            padding: 20px 0 20px;
        }
    }

    &-content-holder {
        max-width: 515px; 
        margin: 0 auto;

        @include below ($s) {
            width: 90%;
            margin: 0 auto;
        }
    }

    &-text {
        padding-bottom: 30px;

        @include below ($s) {
            padding-bottom: 10px;
        }
    }

    &-img-holder {
        margin-top: 20px;
        margin-bottom: 50px;

        @include below ($s) {
            margin-bottom: 40px;
        }

        img {
            width: 100%;
        }
    }

    &-arrow {
        position: absolute;
        left: 50px;
        cursor: pointer;
        top: 40px;

        @include below ($s) {
            display: none;
        }
    }
}

.info-hero-arrow {
    @include above ($s) {
        display: none;
    }
    position: absolute;
    width: 20px !important;
    top: 20px;
    left: 20px;
    cursor: pointer;
}