.start-container {
    background-size: cover;
    height: 100vh;
    width: 100%;
    background-position: center center;
    position: relative;

    @include below ($m) {
        height: calc(var(--vh, 1vh) * 100);
    }
}

.start-logo {
    position: absolute;
    top: 35%; 
    left: 50%; 
    transform: translateX(-50%);
    width: 500px; 

    @include above (2000px) {
        width: 600px;
    }

    @include below ($s) {
        width: 300px;
    }
}

.start-button {
    background-color: $yellow; 
    color: $white;
    font-family: $open; 
    font-weight: 600; 
    font-size: 19px; 
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 40px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 60%;
    border-radius: 10px;
    transition: all .2s ease-in-out;
    
    &.right-corner {
        left: auto; 
        transform: none; 
        right: 100px; 
        top: 100px;
        font-size: 15px;

        @include below ($s) {
            right: auto; 
            left: 50%;
            font-size: 10px; 
            text-align: center; 
            top: 70%;
            transform: translateX(-50%);
        }
    }

    &:hover {
        box-shadow: 0 25px 60px 0 rgba(0, 0, 0, 0.15);
    }
}