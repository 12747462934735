// Colors

$white: #FFFFFF;
$yellow: #FFB900; 
$darkestblue: #2A2E43;
$darkblue: #353A50;
$bluegray: #EFF1F2;
$blue: #454F63;
$lightgrey: #D4D9DB;

//Font 

$open: 'open-sans';

body {
    font-family: $open;
}

// Font color

.c-white {
    color: $white; 
}

.c-white-op {
    color: rgba(255, 255, 255, 0.6);
}

.c-darkblue {
    color: $darkestblue !important;
}

// Text 

.title-xl {
    color: $blue; 
    font-size: 36px; 
    font-weight: 300;

    @include below ($s) {
        font-size: 30px;
    }
}

.p-reg {
    font-size: 16px; 
    color: $blue; 
    line-height: 28px; 

    @include below ($s) {
        font-size: 15px; 
        line-height: 24px;
    }
}

.title-s {
    color: $blue; 
    font-size: 16px; 
    font-weight: 600;
    line-height: 28px; 
}

//background-color

.bg-white {
    background-color: $white;
}

.bg-grey {
    background-color: #EFF1F2;
}

//Containers

.page-container {
    max-width: 780px; 
    margin: 0 auto; 
}

//margins 

.marginb45 {
    margin-bottom: 45px;

    @include below ($s) {
        margin-bottom: 25px;
    }
}

.marginb30 {
    margin-bottom: 30px;

    @include below ($s) {
        margin-bottom: 20px;
    }
}

//padding

.paddingb20 {
    padding-bottom: 20px;
}

.paddingt20 {
    padding-top: 20px;
}

.paddingt50 {
    padding-top: 50px;

    @include below ($s) {
        padding-top: 30px;
    }
}

.paddingb100 {
    padding-bottom: 100px;

    @include below ($s) {
        padding-bottom: 50px;
    }
}

.relative {
    position: relative;
}