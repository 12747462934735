.map-holder {
    height: 100vh;
    width: 100%;
    position: relative;

    @include below ($m) {
        height: calc(var(--vh, 1vh) * 100);
    }
}

#map {
    height: 100%;
    width: 100%;
}

.info-sym {
    position: absolute;
    top: 40px; 
    left: 40px;

    @include below ($s) {
        top: 10px;
        left: 10px;
    }

    img {
        height: 100px;
        @include below ($s) {
            width: 80%;
            height: auto;
        }
    }
}

.search-sym {
    position: absolute;
    top: 30px; 
    right: 40px;

    @include below ($s) {
        top: 10px; 
        right: 10px;
    }

    img {
        @include below ($s) {
            width: 70%;
            float: right;
        }
    }
}

.map-item {
    position: absolute;
    top: 50%;
    background-color: white;
    box-shadow: 0 25px 60px 0 rgba(0,0,0,.15);
    left: 60%;
    z-index: 99999;

    @include below ($s) {
        left: 30%;
        top: 30%;
    }

    &-text {
        min-height: 125px;
        padding: 10px 15px;
        position: relative;

        @include below ($s) {
            min-height: 90px;
        }
    }

    &-arrow {
        position: absolute;
        bottom: 15px;
        right: 15px;

        @include below ($s) {
            width: 16px !important;
        }
    }

    @include below ($s) {
        max-width: 160px;
        min-width: 120px;
    }

    img {
        @include below ($s) {
            width: 100%;
        }
    }
}

.marker-container {
    display: none;
}

// .marker {

// }

.marker-show {
    visibility: visible !important;
}

.marker-icon {
    cursor: pointer;
    display: block;

    &.no-cursor {
        cursor: auto !important;
    }
}

.marker-popup {
    display: none;
    &.visible {
        display: block;
    }
}

.user-geolocation-control {
    top: 65px;
    left: .5em;

    button {
        background-image: url(../img/map-position-icon.png);
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
    }

    @include below($s) {
        top: 9.5em;
        left: 1.1em;
    }
}
